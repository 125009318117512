import React from "react";

export const HistoryHeader = () => {
  return (
    <h2
      style={{
        marginTop: "4.5rem",
        textAlign: "center",
        fontFamily: "Sans-serif",
      }}
    >
      My Ride History
    </h2>
  );
};
