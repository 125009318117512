import React from "react";
import "../WorkingAreas.css";
export default function WorkingAreasHeading() {
  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <h3>Add Working Areas</h3>
    </div>
  );
}
export const MyWorkingZones = () => {
  return (
    <div className="ZoneListHeading">
      <h3>My Working Zones</h3>
    </div>
  );
};
