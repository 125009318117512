import React from "react";
import "./AboutRider.css"; // Import CSS file for styling

export const AboutRider = () => {
  return (
    <div className="about-container">
      <h1 className="red-heading">Become a Rider</h1>
      <div className="text-container">
        <p>
          Our riders enjoy a relaxed work environment where they have the
          freedom to choose their own working zone. They aren't pressured to
          take long rides just to increase earnings. Our system empowers them to
          select areas that suit their preferences and work comfortably within
          them. This flexibility promotes a healthier work-life balance for our
          riders, ultimately enhancing their job satisfaction and performance.
          Join us and experience the freedom of setting your own pace as a
          rider.
        </p>
      </div>
    </div>
  );
};
