import React from "react";
import { NavbarHome } from "./components/NavbarHome";
import DashBoard from "./components/DashBoard";

export default function HomeRestaurant() {
  return (
    <div>
      <NavbarHome />
      <DashBoard />
    </div>
  );
}
